<pf-closable-dialog
  (closeClicked)="closeClicked.emit()"
  [showCloseButton]="showCloseButton()"
  [title]="title()"
  [showLabel]="false"
>
  <div class="px-8">
    <ng-content></ng-content>
  </div>
</pf-closable-dialog>
