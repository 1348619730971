import { CommonModule } from '@angular/common';
import { Component, input, output } from '@angular/core';
import { PfClosableDialogComponent } from 'pf-ui';

@Component({
  selector: 'app-specific-closable-dialog',
  templateUrl: './specific-closable-dialog.component.html',
  styleUrl: './specific-closable-dialog.component.scss',
  standalone: true,
  imports: [CommonModule, PfClosableDialogComponent],
})
export class SpecificClosableDialogComponent {
  closeClicked = output<void>();
  title = input<string>('');
  showCloseButton = input<boolean>(true);
}
